import { createRouter } from "router5";
import browserPlugin from "router5-plugin-browser";

const router = createRouter(
  [
    {
      name: "auth",
      path: "/auth?mode",
      defaultParams: {
        mode: "auth",
      },
    },

    { name: "403", path: "/403" },
    { name: "404", path: "/404" },
    { name: "500", path: "/500" },
    { name: "401", path: "/401" },
    { name: "ownDashboard", path: "/dashboard" },
    { name: "dashboard", path: "/:companyId/dashboard" },
    { name: "reports", path: "/:companyId/reports" },
    { name: "google-auth", path: "/:companyId/google_auth/:accountId" },
    {
      children: [
        { name: "new", path: "/new" },
        { name: "item", path: "/:notify_templates_id" },
      ],
      name: "notify-templates",
      path: "/:companyId/notify_templates",
    },
    {
      children: [
        { name: "new", path: "/new" },
        { name: "item", path: "/:notify_resources_id" },
      ],
      name: "notify-resources",
      path: "/:companyId/notify_resources",
    },
    {
      children: [
        { name: "item", path: "/:mailId" },
        { name: "new", path: "/new" },
      ],
      name: "mail",
      path: "/:companyId/mail",
    },
    { name: "schedule", path: "/:companyId/schedule" },
    {
      name: "google-local-service-ads",
      path: "/:companyId/google_local_service_ads",
    },
    { name: "dispatchers-schedule", path: "/:companyId/dispatchers_schedule" },

    { name: "booking", path: "/:companyId/booking" },
    { name: "conversions", path: "/:companyId/conversions" },
    {
      name: "appointment_reschedule",
      path: "/:companyId/appointment_reschedule",
    },
    { name: "customer_service", path: "/:companyId/customer_service" },
    { name: "tools", path: "/:companyId/tools" },
    {
      children: [
        { name: "areas", path: "/areas" },
        { name: "sources", path: "/sources" },
        { name: "numbers", path: "/numbers" },
      ],
      name: "calls",
      path: "/:companyId/calls",
    },
    {
      children: [
        { name: "new", path: "/new" },
        { name: "item", path: "/:clientId" },
      ],
      name: "clients",
      path: "/:companyId/clients",
    },
    {
      children: [
        { name: "new", path: "/new" },
        { name: "item", path: "/:appointmentId" },
      ],
      name: "appointments",
      path: "/:companyId/appointments",
    },
    {
      children: [{ name: "item", path: "/:jobId" }],
      name: "jobs",
      path: "/:companyId/jobs",
    },
    {
      children: [{ name: "item", path: "/:website_requestId" }],
      name: "website_requests",
      path: "/:companyId/website_requests",
    },
    {
      children: [{ name: "item", path: "/:estimatesId" }],
      name: "estimates",
      path: "/:companyId/estimates",
    },
    {
      children: [
        { name: "item", path: "/:depositsId" },
        { name: "new", path: "/new" },
      ],
      name: "deposits",
      path: "/:companyId/deposits",
    },
    {
      children: [{ name: "item", path: "/:invoicesId" }],
      name: "invoices",
      path: "/:companyId/invoices",
    },
    {
      children: [{ name: "item", path: "/:paymentsId" }],
      name: "payments",
      path: "/:companyId/payments",
    },
    { name: "payment-terminal", path: "/:companyId/payment_terminal" },
    { name: "technician-payment-terminal", path: "/:companyId/technician_payment_terminal" },
    { name: "time-tracking", path: "/:companyId/time_tracking" },
    {
      name: "check-available-tracker",
      path: "/:companyId/check_available_tracker",
    },
    {
      children: [
        { name: "item", path: "/:payoutStaffId" },
        { name: "new", path: "/new" },
      ],
      name: "payout-staff",
      path: "/:companyId/payout_staff",
    },
    {
      children: [
        { name: "item", path: "/:payoutDispatcherId" },
        { name: "new", path: "/new" },
      ],
      name: "payout-dispatcher",
      path: "/:companyId/payout_dispatcher",
    },
    {
      children: [
        { name: "item", path: "/:staffId" },
        { name: "new", path: "/new" },
      ],
      name: "staff",
      path: "/:companyId/staff",
    },
    {
      children: [
        { name: "item", path: "/:payoutsId" },
        { name: "new", path: "/new" },
      ],
      name: "payouts",
      path: "/:companyId/payouts",
    },
    {
      children: [
        { name: "item", path: "/:mailboxesId" },
        { name: "new", path: "/new" },
      ],
      name: "mailboxes",
      path: "/:companyId/mailboxes",
    },
    {
      children: [
        { name: "item", path: "/:googleAccountsId" },
        { name: "new", path: "/new" },
      ],
      name: "google-accounts",
      path: "/:companyId/google_accounts",
    },
    {
      children: [
        { name: "item", path: "/:googleLocalServicesId" },
        { name: "new", path: "/new" },
      ],
      name: "google-local-services",
      path: "/:companyId/google_local_services",
    },
    {
      children: [
        { name: "item", path: "/:dialogId" },
        { name: "itemMessage", path: "/send_message" },
      ],
      name: "sms",
      path: "/:companyId/sms",
    },
    {
      children: [
        { name: "item", path: "/:absenceId" },
        { name: "new", path: "/new" },
      ],
      name: "absences",
      path: "/:companyId/absences",
    },
    {
      children: [
        { name: "item", path: "/:serviceResourceId" },
        { name: "new", path: "/new" },
      ],
      name: "service_resources",
      path: "/:companyId/service_resources",
    },
    {
      children: [
        { name: "item", path: "/:ticketId" },
        { name: "new", path: "/new" },
      ],
      name: "tickets",
      path: "/:companyId/tickets",
    },
    {
      children: [
        { name: "faq", path: "/faq" },
        { name: "tickets", path: "/tickets" },
        { name: "contacts", path: "/contacts" },
      ],
      name: "support",
      path: "/:companyId/support",
    },
    {
      children: [
        { name: "appliances", path: "/appliances" },
        { name: "brands", path: "/brands" },
        { name: "sources", path: "/sources" },
        { name: "areas", path: "/areas" },
        { name: "payments_sevices", path: "/payments_sevices" },
        { name: "companies", path: "/companies" },
        { name: "campaigns", path: "/campaigns" },
        { name: "warehouses", path: "/warehouses" },
        { name: "spam_filters", path: "/spam_filters" },
        { name: "ticket_templates", path: "/ticket_templates" },
        { name: "services", path: "/services" },
        { name: "materials", path: "/materials" },
        { name: "discounts", path: "/discounts" },
      ],
      name: "lists",
      path: "/:companyId/lists",
    },
    {
      children: [
        { name: "new", path: "/new" },
        { name: "item", path: "/:permissionId" },
      ],
      name: "permissions",
      path: "/:companyId/permissions",
    },
    {
      children: [
        { name: "new", path: "/new" },
        { name: "item", path: "/:permissionId" },
      ],
      name: "accounts_permissions",
      path: "/:companyId/accounts_permissions",
    },
    {
      children: [
        { name: "new", path: "/new" },
        { name: "item", path: "/:userId" },
      ],
      name: "users",
      path: "/:companyId/users",
    },
    {
      children: [
        { name: "new", path: "/new" },
        { name: "item", path: "/:usersMenusId" },
      ],
      name: "users_menus",
      path: "/:companyId/users_menus",
    },
    {
      children: [
        { name: "new", path: "/new" },
        { name: "item", path: "/:accountId" },
      ],
      name: "accounts",
      path: "/:companyId/accounts",
    },
    {
      children: [
        { name: "new", path: "/new" },
        { name: "item", path: "/:accountMenusId" },
      ],
      name: "accounts_menus",
      path: "/:companyId/accounts_menus",
    },
    {
      children: [
        { name: "new", path: "/new" },
        { name: "item", path: "/:userGroupsId" },
      ],
      name: "user_groups",
      path: "/:companyId/user_groups",
    },
    {
      children: [
        { name: "new", path: "/new" },
        { name: "item", path: "/:chatChannelId" },
      ],
      name: "chat-channels",
      path: "/:companyId/chat_channels",
    },
    {
      children: [
        { name: "new", path: "/new" },
        { name: "item", path: "/:additionalTimeId" },
      ],
      name: "additional-time",
      path: "/:companyId/additional_time",
    },
    {
      children: [
        {
          children: [
            { name: "new", path: "/new" },
            { name: "item", path: "/:phoneNumbersId" },
          ],
          name: "phoneNumbers",
          path: "/phone_numbers",
        },
        {
          children: [
            { name: "new", path: "/new" },
            { name: "item", path: "/:dispatchersId" },
          ],
          name: "dispatchers",
          path: "/dispatchers",
        },
        {
          children: [
            { name: "new", path: "/new" },
            { name: "item", path: "/:additionalTimeId" },
          ],
          name: "additionalTime",
          path: "/additional_time",
        },
        {
          children: [
            { name: "new", path: "/new" },
            { name: "item", path: "/:absenceId" },
          ],
          name: "absence",
          path: "/absence",
        },
        {
          children: [
            { name: "new", path: "/new" },
            { name: "item", path: "/:groupsId" },
          ],
          name: "groups",
          path: "/groups",
        },
        {
          children: [
            { name: "new", path: "/new" },
            { name: "item", path: "/:callerId" },
          ],
          name: "caller_groups",
          path: "/caller_groups",
        },
        {
          children: [
            { name: "new", path: "/new" },
            { name: "item", path: "/:callFlowsId" },
          ],
          name: "callFlows",
          path: "/call_flows",
        },
        {
          children: [
            { name: "new", path: "/new" },
            { name: "item", path: "/:temporaryClosedId" },
          ],
          name: "temporaryClosed",
          path: "/temporary_closed",
        },
        {
          children: [
            { name: "new", path: "/new" },
            { name: "item", path: "/:callCenterId" },
          ],
          name: "callCenters",
          path: "/call_centers",
        },
        {
          children: [
            { name: "new", path: "/new" },
            { name: "item", path: "/:audioLibraryId" },
          ],
          name: "audioLibrary",
          path: "/audio_library",
        },
      ],
      name: "call_center",
      path: "/:companyId/call_center",
      forwardTo: "call_center.phoneNumbers",
    },
    {
      name: "appointment_calendar",
      path: "/:companyId/technician/calendar",
    },
    {
      name: "profile",
      path: "/:companyId/profile",
    },
    {
      children: [
        { name: "threads", path: "/threads" },
        { name: "drafts", path: "/drafts" },
        { name: "item", path: "/channels/:channelId" },
      ],
      name: "chat",
      path: "/:companyId/chat",
    },
    {
      name: "technician_job",
      path: "/:companyId/technician/job/:jobId",
    },
    {
      name: "technician_absence",
      path: "/:companyId/technician/absence/:absenceId",
    },
    {
      name: "link",
      path: "/link/:linkId/:otherId",
    },
    {
      children: [
        { name: "main", path: "/main/:websiteId" },
        { name: "settings", path: "/settings/:websiteId" },
        { name: "pages", path: "/pages/:websiteId" },
        { name: "new_page", path: "/new_page/:websiteId" },
        { name: "item_page", path: "/:websiteId/item_page/:pageId" },
        { name: "phone_codes", path: "/phone_codes/:websiteId" },
        { name: "phones", path: "/phones/:websiteId" },
        { name: "links", path: "/links/:websiteId" },
        { name: "new", path: "/new" },
      ],
      name: "websites",
      path: "/:companyId/websites",
    },
  ],
  {
    defaultRoute: "ownDashboard",
  }
);

router.usePlugin(browserPlugin());
router.useMiddleware(() => (fromState, toState, done) => {
  if (!fromState || !toState) return done();

  if (fromState.name !== toState.name) window.scrollTo(0, 0);

  done();
});

export default router;
