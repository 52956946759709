import { useState, useEffect } from 'react'
import { useRoute } from "react-router5"
import moment, { Moment } from 'moment'

import Icon from '../../components/Icon'
import PdfView from './PdfView'

import { useAppSelector } from "../../store/hooks"
import { httpClientUpdate, nErrorUpdate } from '../../funcs'

import { NewMaterialProps } from './AddMaterials'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import './LineItems.sass'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: ReportProps
}

interface ReportProps {
  invoice: InvoiceProps
  permissions: {
    technician_invoice_delete: boolean
    technician_invoice_edit: boolean
  }
  edit: {
    invoice_status: {
      invoice_status: string
      invoice_status_id: number
    }[]
  }
}

interface InvoiceProps {
  contact_id: string
  estimate_id: string
  invoice_id: string
  job_id: string
  name: string
  status: string
  status_id: number
  total: number

  comment: string
  service_resource_code: number
  service_resource_nickname: string
  updated_by: string

  deposit_amount: string
  deposit_due_date: string

  items: ItemsProps[]
}

interface ItemsProps {
  description: string
  estimate_id: string
  estimate_item_id: string
  name: string
  price: string
  quantity: number
  sub_type: string
  type: string
  units: {
    unit_id: string
  }[]
}

interface InvoiceEditProps {
  switchPage: (name: string) => void
  id: string
}

export interface DataProps {
  services: { name: string, comment: string, amount: number, quantity: number, unit_id: string[], is_save: boolean, id: string }[]
  discounts: { name: string, comment: string, amount: number, type: string, is_save: boolean, id: string }[]
  materials: NewMaterialProps[]
  deposite: { type: string, amount: number, date: Moment | Date | string }
  note: string
  name?: string
}

export default function InvoiceEdit({ switchPage, id }: InvoiceEditProps) {
  const $router = useRoute()

  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [page, setPage] = useState('')

  const [data, setData] = useState<DataProps>({
    services: [],
    materials: [],
    discounts: [],
    deposite: { type: 'amount', amount: 0.00, date: moment() },
    note: '',
    name: '',
  })

  async function loadInvoices() {
    try {
      const { data: { data: invoiceData, success, error } } = await httpClientUpdate.get('/technician/invoices/' + id, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        let services: { name: string, comment: string, amount: number, quantity: number, unit_id: string[], is_save: boolean, id: string }[] = []
        let materials: NewMaterialProps[] = []
        let discounts: { name: string, comment: string, amount: number, type: string, is_save: boolean, id: string }[] = []

        invoiceData.invoice.items.forEach(item => {
          if (item.type === 'service') {
            services.push({
              name: item.name,
              comment: item.description,
              amount: Number(item.price),
              quantity: item.quantity,
              unit_id: item.units.map(item => item.unit_id),
              is_save: true,
              id: '',
            })
          } else if (item.type === 'material') {
            materials.push({
              name: item.name,
              description: item.description,
              part_number: item.quantity,
              price_per_unit: Number(item.price),
              quantity: item.quantity,
              unit_id: item.units.map(item => item.unit_id),
              taxable: false,
              is_save_price_book: true,
              id: '',
            })
          } else if (item.type === 'discount') {
            discounts.push({
              name: item.name,
              comment: item.description,
              amount: Number(item.price),
              type: item.sub_type ? item.sub_type : 'amount',
              is_save: true,
              id: ''
            })
          }
        })
        setData({
          ...data,
          services: services,
          materials: materials,
          discounts: discounts,
          deposite: {
            ...data.deposite,
            amount: Number(invoiceData.invoice.deposit_amount),
            date: moment(invoiceData.invoice.deposit_due_date)
          },
          note: invoiceData.invoice.comment,
          name: invoiceData.invoice.name,
        })

      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  useEffect(() => {
    loadInvoices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getTotalWithDiscount() {
    let total = 0
    let discount = 0

    data.services.forEach(item => {
      total = total + Number(item.amount * item.quantity)
    })

    data.materials.forEach(item => {
      total = total + (Number(item.price_per_unit) * Number(item.quantity))
    })

    data.discounts.forEach(item => {
      discount = discount + Number(item.amount)
    })


    return `$${(total - discount).toFixed(2)}`
  }

  function getSubTotal() {
    let total = 0

    data.services.forEach(item => {
      total = total + Number(item.amount * item.quantity)
    })

    data.materials.forEach(item => {
      total = total + (Number(item.price_per_unit) * Number(item.quantity))
    })

    return `$${total.toFixed(2)}`
  }

  return (
    <>
      {
        page === 'pdf_view' &&
        <PdfView
          setPage={setPage}
          data={data}
          name="Invoice"
          id={id}
        />
      }

      {
        page === '' &&
        <div className="EstimatePage_Add entity-edit">
          <div className="wrapper flex-container sb">
            <div className='row'>
              <Icon style={{ width: '24px', height: '24px', fill: '#393939' }} viewBox="0 0 1024 1024" icon="unordered-lists" />

              <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>Invoice</h1>
            </div>

            <div style={{ gap: '30px' }} className='row'>
              <button
                className="_zeroed _iconed _blue"
                onClick={() => switchPage('main')}
              >
                <Icon
                  style={{ width: '24px', height: '24px', transform: 'rotate(180deg)' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              <button
                className="_zeroed _iconed _blue"
                onClick={() => setPage('pdf_view')}
              >

                <Icon
                  style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                  viewBox="0 0 1024 1024"
                  icon="zoom-17"
                />
              </button>

              <button
                className="_zeroed _iconed _blue"
              >
                <Icon
                  viewBox="0 0 1024 1024"
                  style={{
                    width: '24px',
                    minWidth: '24px',
                    height: '24px',
                    cursor: 'pointer',
                  }}
                  icon="send-21"
                />
              </button>
            </div>
          </div>

          <div className='line-items-head'>
            <div className='line-items-head-name'> {data.name} </div>
          </div>

          <div className='line-items-content service'>
            <div className='line-items-col-show'>
              <div className='line-items-title'>
                Services
              </div>

              {
                data.services.map((item, index) => (
                  <div
                    key={index}
                    className="services-item services-item-show"
                  >
                    <div className='services-item-info'>
                      <div className='services-item-info-name'>
                        {item.name}
                      </div>

                      <div className='services-item-info-comment'>
                        {`Qty ${item.quantity} @ $${item.amount.toFixed(2)}`}
                      </div>

                      <div className='services-item-info-comment'>
                        {item.comment}
                      </div>
                    </div>

                    <div className='services-item-info-price'>
                      <div style={{ whiteSpace: 'nowrap', color: '#000' }} className='services-item-info-comment'>
                        $ {(item.amount * item.quantity).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ))
              }

              <div className='line-items-title'>
                Materials
              </div>

              {
                data.materials.map((item, index) => (
                  <div
                    key={index}
                    className="services-item services-item-show"
                  >
                    <div className='services-item-info'>
                      <div className='services-item-info-name'>
                        {item.name}
                      </div>

                      <div className='services-item-info-comment'>
                        {`Qty ${item.quantity} @ $${item.price_per_unit.toFixed(2)}`}
                      </div>

                      <div className='services-item-info-comment'>
                        {item.description}
                      </div>
                    </div>

                    <div className='services-item-info-price'>
                      <div style={{ whiteSpace: 'nowrap', color: '#000' }} className='services-item-info-comment'>
                        $ {(item.price_per_unit * item.quantity).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ))
              }

              <div style={{ marginTop: '0' }} className='line-items-subtotal-show'>
                <span>
                  Subtotal
                </span>

                <span>
                  {getSubTotal()}
                </span>
              </div>
            </div>

            {
              !!data.discounts.length &&
              <div className='line-items-content service'>
                <div className='line-items-col-show'>
                  {
                    data.discounts.map((item, index) => (
                      <div
                        key={index}
                        className="services-item services-item-show"
                      >
                        <div className='services-item-info'>
                          <div className='services-item-info-name'>
                            Discount: {item.name}
                          </div>

                          <div className='services-item-info-comment'>
                            Discount ($ {(item.amount).toFixed(2)})
                          </div>
                        </div>

                        <div className='services-item-info-price'>
                          <div style={{ whiteSpace: 'nowrap' }} className='services-item-info-comment'>
                            - $ {(item.amount).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            }

            <div className='line-items-content service'>
              <div className='line-items-col-show'>
                <div style={{ marginTop: '0' }} className='line-items-subtotal-show'>
                  <span>
                    Total
                  </span>

                  <span>
                    {getTotalWithDiscount()}
                  </span>
                </div>
              </div>
            </div>

            <div className='line-items-content service'>
              <div className='line-items-col-show'>
                <div className="services-item services-item-show">
                  <div className='services-item-info'>
                    <div className='services-item-info-name'>
                      Deposit
                    </div>

                    <div className='services-item-info-comment'>
                      Due on {moment(data.deposite.date).format('MM/DD/YYYY')}
                    </div>
                  </div>

                  <div className='services-item-info-price'>
                    <div style={{ whiteSpace: 'nowrap' }} className='services-item-info-comment'>
                      ${(data.deposite.amount).toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='line-items-content service'>
              <div className='line-items-col-show'>
                <div className="services-item services-item-show">
                  <div className='services-item-info'>
                    <div className='services-item-info-name'>
                      Note
                    </div>

                    <div className='services-item-info-comment'>
                      {data.note}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
