import Checkbox from '../../components/Checkbox'
import { UnitProps } from '../Jobs/ItemTech'

interface UnitTableProps {
  units: UnitProps[]
  unit_id: string[]
  addUnit: (value: string[]) => void
}

export default function UnitTable({
  units,
  unit_id,
  addUnit
}: UnitTableProps) {

  function handleCheck(id: string) {
    if (unit_id.includes(id)) {
      addUnit(unit_id.filter(item => item !== id))
    } else {
      let updated = unit_id.map(item => item)
      updated.push(id)
      addUnit(updated)
    }
  }
  return (
    <div
      className='line-items-row'
      style={{ flexDirection: 'column' }}
    >
      <div className='name'>
        Units
      </div>

      <table className='table'>
        <thead>
          <tr>
            <th></th>

            <th>Name</th>

            <th>Desc</th>
          </tr>
        </thead>

        {
          units.map(item => (
            <tr>
              <td>
                <Checkbox
                  contents=''
                  value={unit_id.includes(item.unit_id)}
                  onChange={() => handleCheck(item.unit_id)}
                />
              </td>
              <td>
                {`${item.appliance === 'Other' ? item.other_appliance : item.appliance} ${item.unit_type !== 'Residential' ? '(' + item.unit_type + ')' : ''}: ${item.appliance_brand === 'Other' ? item.other_brand : item.appliance_brand}`}
              </td>
              <td> {item.description} </td>
            </tr>
          ))
        }
      </table>
    </div>
  )
}
