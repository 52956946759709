import { useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import classNames from 'classnames'

import Icon from '../../components/Icon'
import AddDiscounts from './AddDiscounts'
import AddNote from './AddNote'
import AddService from './AddService'
import EditService from './EditService'
import EditMaterial from './EditMaterials'
import EditDiscount from './EditDiscounts'
import AddDeposite, { DepositeProps } from './AddDeposite'
import AddMaterials, { NewMaterialProps } from './AddMaterials'
import PdfView from './PdfView'

import { UnitProps } from '../Jobs/ItemTech'
import { httpClientUpdate } from '../../funcs'
import { useAppSelector } from '../../store/hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios'

import './LineItems.sass'

interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: DataReport
}

interface DataReport {
  edit: {
    estimate_status: {
      estimate_status: string
      estimate_status_id: number
    }
  }
  estimate: {
    comment: string
    contact_id: string
    deposit_amount: string
    deposit_due_date: string
    estimate_id: string
    expiration_date: string
    job_id: string
    name: string
    service_resource_code: number
    service_resource_nickname: string
    status_id: number
    total: number
    items: ItemsProps[]

    is_converted: boolean
  }
  permissions: {
    technician_estimate_delete: boolean
    technician_estimate_edit: boolean
    technician_invoice_add: boolean
    technician_job_all_delete: boolean
    technician_job_all_edit: boolean
  }
}

interface ItemsProps {
  description: string
  estimate_id: string
  estimate_item_id: string
  name: string
  price: string
  quantity: number
  sub_type: string
  type: string
  units: {
    unit_id: string
  }[]
}

interface LineItemsProps {
  switchPage: (name: string) => void
  jobId: string
  client_id: string
  edit: {
    estimate_status: string
    estimate_status_id: number
  }[]
  units: UnitProps[]
  id: string
}

export interface DataProps {
  services: { name: string, comment: string, amount: number, quantity: number, unit_id: string[], is_save: boolean, id: string }[]
  discounts: { name: string, comment: string, amount: number, type: string, is_save: boolean, id: string }[]
  materials: NewMaterialProps[]
  deposite: { type: string, amount: number, date: Moment | Date | string }
  note: string
  name?: string
}

export default function LineItemsEdit({
  switchPage,
  units,
  jobId,
  client_id,
  id,
}: LineItemsProps) {
  const activeAccountId = useAppSelector((store) => store.activeAccountId)

  const [removeModal, setRemoveModal] = useState('')
  const [removeIndex, setRemoveIndex] = useState(0)
  const [editIndex, setEditIndex] = useState(0)
  const [page, setPage] = useState('')
  const [data, setData] = useState<DataProps>({
    services: [],
    materials: [],
    discounts: [],
    deposite: { type: 'amount', amount: 0.00, date: moment() },
    note: '',
    name: '',
  })

  const [mode, setMode] = useState('')
  const [disabledConvertBtn, setDisabledConvertBtn] = useState(false)

  useEffect(() => {
    if (mode === '') {
      getStore()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  async function getStore() {
    try {
      // api/technician/estimates/88888301bkmgqr4h3o?account_id=88888
      const { data: { data: invoiceData, success, } } = await httpClientUpdate.get('/technician/estimates/' + id, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        let services: { name: string, comment: string, amount: number, quantity: number, unit_id: string[], is_save: boolean, id: string }[] = []
        let materials: NewMaterialProps[] = []
        let discounts: { name: string, comment: string, amount: number, type: string, is_save: boolean, id: string }[] = []

        invoiceData.estimate.items.forEach(item => {
          if (item.type === 'service') {
            services.push({
              name: item.name,
              comment: item.description,
              amount: Number(item.price),
              quantity: item.quantity,
              unit_id: item.units.map(item => item.unit_id),
              is_save: true,
              id: '',
            })
          } else if (item.type === 'material') {
            materials.push({
              name: item.name,
              description: item.description,
              part_number: item.quantity,
              price_per_unit: Number(item.price),
              quantity: item.quantity,
              unit_id: item.units.map(item => item.unit_id),
              taxable: false,
              is_save_price_book: true,
              id: '',
            })
          } else if (item.type === 'discount') {
            discounts.push({
              name: item.name,
              comment: item.description,
              amount: Number(item.price),
              type: item.sub_type ? item.sub_type : 'amount',
              is_save: true,
              id: ''
            })
          }
        })
        setDisabledConvertBtn(!!invoiceData.estimate.is_converted)
        setData({
          ...data,
          services: services,
          materials: materials,
          discounts: discounts,
          deposite: {
            ...data.deposite,
            amount: Number(invoiceData.estimate.deposit_amount),
            date: moment(invoiceData.estimate.deposit_due_date)
          },
          note: invoiceData.estimate.comment,
          name: invoiceData.estimate.name,
        })
      }
    }
    catch (error: Error | AxiosError | unknown) { }
  }

  async function handleSaveEstimate() {
    try {
      let items: {
        description?: string,
        units?: string[],
        type: string,
        name: string,
        price: number,
        quantity?: number,
        subtype?: string,
      }[] = []

      data.services.length && data.services.forEach(item => {
        items.push({
          description: item.comment,
          units: item.unit_id,
          type: 'service',
          name: item.name,
          price: item.amount,
          quantity: item.quantity
        })
      })

      data.materials.length && data.materials.forEach(item => {
        items.push({
          description: item.description,
          units: item.unit_id,
          type: 'material',
          name: item.name,
          price: item.price_per_unit,
          quantity: item.quantity
        })
      })

      data.discounts.length && data.discounts.forEach(item => {
        items.push({
          type: 'discount',
          description: item.comment,
          name: item.name,
          price: item.amount,
          subtype: item.type,
          quantity: 1,
        })
      })

      // api/technician/estimates/88888301ybaxtc17nv?account_id=88888
      const response = await httpClientUpdate.put(`/technician/estimates/${id}`, {
        account_id: activeAccountId,
        total: total().toFixed(2),
        contact_id: client_id,
        job_id: jobId,
        comment: data.note,
        deposit_amount: data.deposite.amount,
        deposit_due_date: moment(data.deposite.date).format('YYYY-MM-DD'),
        items: items
      })
      setPage('')

      if (response.data.success) {
        getStore()
      }

    } catch (error) { }
  }

  async function handleSaveNewEstimate() {
    try {
      let items: {
        description?: string,
        units?: string[],
        type: string,
        name: string,
        price: number,
        quantity?: number,
        subtype?: string,
      }[] = []

      data.services.length && data.services.forEach(item => {
        items.push({
          description: item.comment,
          units: item.unit_id,
          type: 'service',
          name: item.name,
          price: item.amount,
          quantity: item.quantity
        })
      })

      data.materials.length && data.materials.forEach(item => {
        items.push({
          description: item.description,
          units: item.unit_id,
          type: 'material',
          name: item.name,
          price: item.price_per_unit,
          quantity: item.quantity
        })
      })

      data.discounts.length && data.discounts.forEach(item => {
        items.push({
          type: 'discount',
          description: item.comment,
          name: item.name,
          price: item.amount,
          subtype: item.type,
          quantity: 1,
        })
      })

      // /api/technician/estimates
      const response = await httpClientUpdate.post(`/technician/estimates`, {
        account_id: activeAccountId,
        total: total().toFixed(2),
        contact_id: client_id,
        job_id: jobId,
        comment: data.note,
        deposit_amount: data.deposite.amount,
        deposit_due_date: moment(data.deposite.date).format('YYYY-MM-DD'),
        items: items
      })
      if (response.data.success) {
        switchPage('main')
      }

    } catch (error) { }
  }

  async function handleConvertToInvoice() {
    try {
      // api/technician/estimates/{ESTIMATE_ID}/make-invoice
      const response = await httpClientUpdate.post(`/technician/estimates/${id}/make-invoice`, {
        account_id: activeAccountId,
      })
      if (response.data.success) {
        setDisabledConvertBtn(true)
      }
    } catch (error) { }
  }

  function handleClone() {
    setMode('clone')
    setPage('editing')
  }

  function handleCancelEdit() {
    setPage('')
  }

  function handleCancelClone() {
    setMode('')
    setPage('')
  }

  function handleAdd(name: string) {
    setPage(name)
  }

  function handleEdit(name: string, index: number) {
    setPage(name)
    setEditIndex(index)
  }


  function handleSaveService(name: string, comment: string, amount: number, quantity: number, unit_id: string[], is_save: boolean, id: string) {
    let updated = data.services.map(item => item)
    updated.push({ name, amount, comment, quantity, unit_id, is_save, id })

    setPage('editing')
    setData({
      ...data,
      services: updated
    })
  }

  function handleUpdatedService(name: string, comment: string, amount: number, quantity: number, unit_id: string[], is_save: boolean, id: string, index: number) {
    setData({
      ...data,
      services: data.services.map((item, idx) => {
        if (idx === index) {
          return { name, amount, comment, quantity, unit_id, is_save, id }
        } else {
          return { ...item }
        }
      })
    })
    setEditIndex(0)
    setPage('editing')
  }

  function handleSaveDiscounts(name: string, comment: string, amount: number, type: string, is_save: boolean, id: string) {
    let updated = data.discounts.map(item => item)
    updated.push({ name, amount, comment, type, is_save, id })

    setPage('editing')
    setData({
      ...data,
      discounts: updated
    })
  }

  function handleUpdatedDiscount(name: string, comment: string, amount: number, type: string, is_save: boolean, id: string, index: number) {
    setData({
      ...data,
      discounts: data.discounts.map((item, idx) => {
        if (idx === index) {
          return { name, amount, comment, type, is_save, id }
        } else {
          return { ...item }
        }
      })
    })
    setEditIndex(0)
    setPage('editing')
  }

  function handleSaveMaterials(value: NewMaterialProps) {
    let updated = data.materials.map(item => item)
    updated.push({ ...value })

    setPage('editing')
    setData({
      ...data,
      materials: updated
    })
  }

  function handleUpdatedMaterial(value: NewMaterialProps, index: number) {
    setData({
      ...data,
      materials: data.materials.map((item, idx) => {
        if (idx === index) {
          return { ...value }
        } else {
          return { ...item }
        }
      })
    })
    setEditIndex(0)
    setPage('editing')
  }

  function handleSaveNewMaterial(value: NewMaterialProps) {
    let updated = data.materials.map(item => item)
    updated.push({ ...value })

    setPage('editing')
    setData({
      ...data,
      materials: updated
    })
  }

  function handleSaveNote(note: string) {
    setPage('editing')
    setData({
      ...data,
      note: note
    })
  }

  function handleSaveDeposite(data_deposite: DepositeProps) {
    setPage('editing')
    setData({
      ...data,
      deposite: data_deposite
    })
  }

  function handleRemoveService(index: number) {
    setData({
      ...data,
      services: data.services.filter((item, idx) => idx !== index)
    })
    setRemoveIndex(0)
    setRemoveModal('')
  }

  function handleRemoveMaterials(index: number) {
    setData({
      ...data,
      materials: data.materials.filter((item, idx) => idx !== index)
    })
    setRemoveIndex(0)
    setRemoveModal('')
  }

  function handleRemoveDiscounts(index: number) {
    setData({
      ...data,
      discounts: data.discounts.filter((item, idx) => idx !== index)
    })
    setRemoveIndex(0)
    setRemoveModal('')
  }

  function handleDeleteDeposite() {
    setData({
      ...data,
      deposite: { type: 'amount', amount: 0.00, date: moment() },
    })
    setRemoveModal('')
  }

  function handleDeleteNote() {
    setData({
      ...data,
      note: '',
    })
    setRemoveModal('')
  }

  function getSubTotal() {
    let total = 0

    data.services.forEach(item => {
      total = total + Number(item.amount * item.quantity)
    })

    data.materials.forEach(item => {
      total = total + (Number(item.price_per_unit) * Number(item.quantity))
    })

    return `$${total.toFixed(2)}`
  }

  function getTotalWithDiscount() {
    let total = 0
    let discount = 0

    data.services.forEach(item => {
      total = total + Number(item.amount * item.quantity)
    })

    data.materials.forEach(item => {
      total = total + (Number(item.price_per_unit) * Number(item.quantity))
    })

    data.discounts.forEach(item => {
      discount = discount + Number(item.amount)
    })


    return `$${(total - discount).toFixed(2)}`
  }

  function total() {
    let total = 0
    let discount = 0

    data.services.forEach(item => {
      total = total + Number(item.amount * item.quantity)
    })

    data.materials.forEach(item => {
      total = total + (Number(item.price_per_unit) * Number(item.quantity))
    })

    data.discounts.forEach(item => {
      discount = discount + Number(item.amount)
    })


    return total - discount
  }

  return (
    <>
      {
        page === 'pdf_view' &&
        <PdfView
          setPage={setPage}
          data={data}
          name="Estimate"
          id={id}
        />
      }

      {
        page === 'add_service' &&
        <AddService
          setPage={setPage}
          data={data}
          setData={setData}
          handleSave={handleSaveService}
          units={units}
        />
      }

      {
        page === 'edit_service' &&
        <EditService
          setPage={setPage}
          service={data.services[editIndex]}
          index={editIndex}
          handleSave={handleUpdatedService}
          units={units}
        />
      }

      {
        page === 'add_materials' &&
        <AddMaterials
          setPage={setPage}
          data={data}
          setData={setData}
          handleSave={handleSaveMaterials}
          handleSaveNewMaterial={handleSaveNewMaterial}
          units={units}
        />
      }

      {
        page === 'edit_materials' &&
        <EditMaterial
          setPage={setPage}
          service={data.materials[editIndex]}
          index={editIndex}
          handleSave={handleUpdatedMaterial}
          units={units}
        />
      }

      {
        page === 'add_discounts' &&
        <AddDiscounts
          setPage={setPage}
          data={data}
          setData={setData}
          handleSave={handleSaveDiscounts}
        />
      }

      {
        page === 'edit_discounts' &&
        <EditDiscount
          setPage={setPage}
          service={data.discounts[editIndex]}
          index={editIndex}
          handleSave={handleUpdatedDiscount}
        />
      }

      {
        page === 'add_note' &&
        <AddNote
          setPage={setPage}
          data={data}
          setData={setData}
          handleSave={handleSaveNote}
          note={data.note}
        />
      }

      {
        page === 'add_deposite' &&
        <AddDeposite
          setPage={setPage}
          data={data}
          setData={setData}
          handleSave={handleSaveDeposite}
          deposite={data.deposite}
        />
      }

      {
        page === '' &&
        <div className="EstimatePage_Add entity-edit">
          <div className="wrapper flex-container sb">
            <div className='row'>
              <Icon style={{ width: '24px', height: '24px', fill: '#393939' }} viewBox="0 0 1024 1024" icon="unordered-lists" />

              <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>Estimate</h1>
            </div>

            <div style={{ gap: '30px' }} className='row'>
              <button
                className="_zeroed _iconed _blue"
                onClick={() => switchPage('main')}
              >
                <Icon
                  style={{ width: '24px', height: '24px', transform: 'rotate(180deg)' }}
                  viewBox="0 0 24 24"
                  icon="arrow-25"
                />
              </button>

              <button
                className="_zeroed _iconed _blue"
                onClick={() => setPage('pdf_view')}
              >

                <Icon
                  style={{ width: '24px', height: '24px', verticalAlign: 'middle', overflow: 'hidden' }}
                  viewBox="0 0 1024 1024"
                  icon="zoom-17"
                />
              </button>

              <button
                className="_zeroed _iconed _blue"
                disabled={disabledConvertBtn}
                onClick={() => setPage('editing')}
              >
                <Icon icon="pencil-14" />
              </button>
            </div>
          </div>

          <div className='line-items-head'>
            <div className='line-items-head-name'> {data.name} </div>

            <div className='line-items-head-btns'>
              <button>Send</button>

              <button
                className={classNames({
                  'button-save-disabled': disabledConvertBtn
                })}
                onClick={() => !disabledConvertBtn && handleConvertToInvoice()}
              >
                Convert to Invoice
              </button>

              <button onClick={handleClone}>
                Clone
              </button>
            </div>
          </div>

          <div className='line-items-content service'>
            <div className='line-items-col-show'>
              <div className='line-items-title'>
                Services
              </div>

              {
                data.services.map((item, index) => (
                  <div
                    key={index}
                    className="services-item services-item-show"
                  >
                    <div className='services-item-info'>
                      <div className='services-item-info-name'>
                        {item.name}
                      </div>

                      <div className='services-item-info-comment'>
                        {`Qty ${item.quantity} @ $${item.amount.toFixed(2)}`}
                      </div>

                      <div className='services-item-info-comment'>
                        {item.comment}
                      </div>
                    </div>

                    <div className='services-item-info-price'>
                      <div style={{ whiteSpace: 'nowrap', color: '#000' }} className='services-item-info-comment'>
                        $ {(item.amount * item.quantity).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ))
              }

              <div className='line-items-title'>
                Materials
              </div>

              {
                data.materials.map((item, index) => (
                  <div
                    key={index}
                    className="services-item services-item-show"
                  >
                    <div className='services-item-info'>
                      <div className='services-item-info-name'>
                        {item.name}
                      </div>

                      <div className='services-item-info-comment'>
                        {`Qty ${item.quantity} @ $${item.price_per_unit.toFixed(2)}`}
                      </div>

                      <div className='services-item-info-comment'>
                        {item.description}
                      </div>
                    </div>

                    <div className='services-item-info-price'>
                      <div style={{ whiteSpace: 'nowrap', color: '#000' }} className='services-item-info-comment'>
                        $ {(item.price_per_unit * item.quantity).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ))
              }

              <div style={{ marginTop: '0' }} className='line-items-subtotal-show'>
                <span>
                  Subtotal
                </span>

                <span>
                  {getSubTotal()}
                </span>
              </div>
            </div>

            {
              !!data.discounts.length &&
              <div className='line-items-content service'>
                <div className='line-items-col-show'>
                  {
                    data.discounts.map((item, index) => (
                      <div
                        key={index}
                        className="services-item services-item-show"
                      >
                        <div className='services-item-info'>
                          <div className='services-item-info-name'>
                            Discount: {item.name}
                          </div>

                          <div className='services-item-info-comment'>
                            Discount ($ {(item.amount).toFixed(2)})
                          </div>
                        </div>

                        <div className='services-item-info-price'>
                          <div style={{ whiteSpace: 'nowrap' }} className='services-item-info-comment'>
                            - $ {(item.amount).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            }

            <div className='line-items-content service'>
              <div className='line-items-col-show'>
                <div style={{ marginTop: '0' }} className='line-items-subtotal-show'>
                  <span>
                    Total
                  </span>

                  <span>
                    {getTotalWithDiscount()}
                  </span>
                </div>
              </div>
            </div>

            <div className='line-items-content service'>
              <div className='line-items-col-show'>
                <div className="services-item services-item-show">
                  <div className='services-item-info'>
                    <div className='services-item-info-name'>
                      Deposit
                    </div>

                    <div className='services-item-info-comment'>
                      Due on {moment(data.deposite.date).format('MM/DD/YYYY')}
                    </div>
                  </div>

                  <div className='services-item-info-price'>
                    <div style={{ whiteSpace: 'nowrap' }} className='services-item-info-comment'>
                      ${(data.deposite.amount).toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='line-items-content service'>
              <div className='line-items-col-show'>
                <div className="services-item services-item-show">
                  <div className='services-item-info'>
                    <div className='services-item-info-name'>
                      Note
                    </div>

                    <div className='services-item-info-comment'>
                      {data.note}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {
        page === 'editing' &&
        <div className="EstimatePage_Add entity-edit">
          <div className="wrapper flex-container sb editing">
            <div>
              {
                mode === '' &&
                <button
                  className="button-cancel"
                  onClick={handleCancelEdit}
                >
                  Cancel
                </button>
              }

              {
                mode === 'clone' &&
                <button
                  className="button-cancel"
                  onClick={handleCancelClone}
                >
                  Cancel
                </button>
              }
            </div>


            {
              mode === '' &&
              <div>
                <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>Edit Estimate</h1>
              </div>
            }

            {
              mode === 'clone' &&
              <div>
                <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#393939' }}>New Estimate</h1>
              </div>
            }

            {
              mode === '' &&
              <div>
                <button
                  className="button-save"
                  onClick={() => handleSaveEstimate()}
                >
                  Save
                </button>
              </div>
            }

            {
              mode === 'clone' &&
              <div>
                <button
                  className="button-save"
                  onClick={() => handleSaveNewEstimate()}
                >
                  Save
                </button>
              </div>
            }
          </div>

          <div className='line-items-content editing'>
            <div className='line-items-col'>
              <div className='line-items-title'>
                Services
              </div>

              {
                data.services.map((item, index) => (
                  <div
                    key={index}
                    className='line-items-editing-row'
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEdit('edit_service', index)}
                  >
                    <div className='row'>
                      <div
                        className='minus'
                        onClick={(event) => {
                          event.stopPropagation()
                          setRemoveIndex(index)
                          setRemoveModal('service')
                        }}
                      >
                        -
                      </div>

                      <span> {item.name} ({`Qty ${item.quantity} @ $${item.amount.toFixed(2)}`}) </span>
                    </div>

                    <div className='row'>
                      <span> $ {(item.amount * item.quantity).toFixed(2)} </span>
                      <Icon
                        style={{ width: '16px', height: '16px', fill: '#ccc', marginLeft: '20px' }}
                        viewBox="0 0 24 24"
                        icon="arrow-25"
                      />
                    </div>
                  </div>
                ))
              }

              <div
                className='line-items-editing-row'
                style={{ cursor: 'pointer' }}
                onClick={() => handleAdd('add_service')}
              >
                <div className='row'>
                  <div className='plus'>+</div>

                  <span>Add Service</span>
                </div>

                <div>
                  <Icon
                    style={{ width: '16px', height: '16px', fill: '#ccc' }}
                    viewBox="0 0 24 24"
                    icon="arrow-25"
                  />
                </div>
              </div>

              <div className='line-items-title'>
                Materials
              </div>

              {
                data.materials.map((item, index) => (
                  <div
                    key={index}
                    className='line-items-editing-row'
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEdit('edit_materials', index)}
                  >
                    <div className='row'>
                      <div
                        className='minus'
                        onClick={(event) => {
                          event.stopPropagation()
                          setRemoveIndex(index)
                          setRemoveModal('material')
                        }}
                      >
                        -
                      </div>

                      <span> {item.name} ({`Qty ${item.quantity} @ $${item.price_per_unit.toFixed(2)}`}) </span>
                    </div>

                    <div className='row'>
                      <span> $ {(item.price_per_unit * item.quantity).toFixed(2)} </span>
                      <Icon
                        style={{ width: '16px', height: '16px', fill: '#ccc', marginLeft: '20px' }}
                        viewBox="0 0 24 24"
                        icon="arrow-25"
                      />
                    </div>
                  </div>
                ))
              }

              <div
                className='line-items-editing-row'
                style={{ cursor: 'pointer' }}
                onClick={() => handleAdd('add_materials')}
              >
                <div className='row'>
                  <div className='plus'>+</div>

                  <span>Add Materials</span>
                </div>

                <div>
                  <Icon
                    style={{ width: '16px', height: '16px', fill: '#ccc' }}
                    viewBox="0 0 24 24"
                    icon="arrow-25"
                  />
                </div>
              </div>
            </div>

            <div className='line-items-col'>
              <div className='line-items-title'>
                Discounts
              </div>

              {
                data.discounts.map((item, index) => (
                  <div
                    key={index}
                    className='line-items-editing-row'
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleEdit('edit_discounts', index)}
                  >
                    <div className='row'>
                      <div
                        className='minus'
                        onClick={(event) => {
                          event.stopPropagation()
                          setRemoveIndex(index)
                          setRemoveModal('discount')
                        }}
                      >
                        -
                      </div>

                      <span> {item.name} </span>
                    </div>

                    <div className='row'>
                      <span> {item.type === 'amount' ? `$ ${Number(item.amount).toFixed(2)}` : `${Number(item.amount).toFixed(2)} %`} </span>
                      <Icon
                        style={{ width: '16px', height: '16px', fill: '#ccc', marginLeft: '20px' }}
                        viewBox="0 0 24 24"
                        icon="arrow-25"
                      />
                    </div>
                  </div>
                ))
              }

              <div
                className='line-items-editing-row'
                style={{ cursor: 'pointer' }}
                onClick={() => handleAdd('add_discounts')}
              >
                <div className='row'>
                  <div className='plus'>+</div>

                  <span>Add Discounts</span>
                </div>

                <div>
                  <Icon
                    style={{ width: '16px', height: '16px', fill: '#ccc' }}
                    viewBox="0 0 24 24"
                    icon="arrow-25"
                  />
                </div>
              </div>
            </div>

            <div className='line-items-col'>
              <div className='line-items-title'>
                Deposit
              </div>

              <div
                className='line-items-editing-row'
                style={{ cursor: 'pointer' }}
                onClick={() => handleAdd('add_deposite')}
              >
                {
                  !!data.deposite.amount ?
                    <div
                      className='row'
                    >
                      <div
                        className='minus'
                        onClick={(event) => {
                          event.stopPropagation()
                          setRemoveModal('deposit')
                        }}
                      >
                        -
                      </div>

                      <span>Due date: {moment(data.deposite.date).format('MM/DD/YYYY')} </span>
                    </div> :
                    <div
                      className='row'
                    >
                      <div className='plus'>+</div>

                      <span>Add Deposit</span>
                    </div>
                }

                <div className='row'>
                  {
                    !!data.deposite.amount &&
                    <span> {data.deposite.type === 'amount' ? `$ ${Number(data.deposite.amount).toFixed(2)}` : `${Number(data.deposite.amount).toFixed(2)} %`} </span>

                  }
                  <Icon
                    style={{ width: '16px', height: '16px', fill: '#ccc', marginLeft: '20px' }}
                    viewBox="0 0 24 24"
                    icon="arrow-25"
                  />
                </div>
              </div>
            </div>

            <div className='line-items-col'>
              <div className='line-items-title'>
                NOTE
              </div>

              <div
                className='line-items-editing-row'
                style={{ cursor: 'pointer' }}
                onClick={() => handleAdd('add_note')}
              >
                {
                  data.note ?
                    <div
                      className='row'
                    >
                      <div
                        className='minus'
                        onClick={(event) => {
                          event.stopPropagation()
                          setRemoveModal('note')
                        }}
                      >
                        -
                      </div>

                      <span> {data.note} </span>
                    </div> :
                    <div
                      className='row'
                    >
                      <div className='plus'>+</div>

                      <span>Add Note</span>
                    </div>
                }

                <div>
                  <Icon
                    style={{ width: '16px', height: '16px', fill: '#ccc' }}
                    viewBox="0 0 24 24"
                    icon="arrow-25"
                  />
                </div>
              </div>
            </div>
          </div>

          {removeModal === 'service' && (
            <div className="item-delete-popup" onClick={() => setRemoveModal('')}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Service
                </div>

                <div className="buttons">

                  <button
                    className="_bordered _green"
                    onClick={() => setRemoveModal('')}
                  >
                    Cancel
                  </button>

                  <button
                    className="_bordered _red"
                    onClick={() => handleRemoveService(removeIndex)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}

          {removeModal === 'material' && (
            <div className="item-delete-popup" onClick={() => setRemoveModal('')}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Material
                </div>

                <div className="buttons">

                  <button
                    className="_bordered _green"
                    onClick={() => setRemoveModal('')}
                  >
                    Cancel
                  </button>

                  <button
                    className="_bordered _red"
                    onClick={() => handleRemoveMaterials(removeIndex)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}

          {removeModal === 'discount' && (
            <div className="item-delete-popup" onClick={() => setRemoveModal('')}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Discount
                </div>

                <div className="buttons">

                  <button
                    className="_bordered _green"
                    onClick={() => setRemoveModal('')}
                  >
                    Cancel
                  </button>

                  <button
                    className="_bordered _red"
                    onClick={() => handleRemoveDiscounts(removeIndex)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}

          {removeModal === 'note' && (
            <div className="item-delete-popup" onClick={() => setRemoveModal('')}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Note
                </div>

                <div className="buttons">

                  <button
                    className="_bordered _green"
                    onClick={() => setRemoveModal('')}
                  >
                    Cancel
                  </button>

                  <button
                    className="_bordered _red"
                    onClick={() => handleDeleteNote()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}

          {removeModal === 'deposit' && (
            <div className="item-delete-popup" onClick={() => setRemoveModal('')}>

              <div className="wrapper" onClick={(e) => e.stopPropagation()}>

                <div className="title">
                  Delete Deposite
                </div>

                <div className="buttons">

                  <button
                    className="_bordered _green"
                    onClick={() => setRemoveModal('')}
                  >
                    Cancel
                  </button>

                  <button
                    className="_bordered _red"
                    onClick={() => handleDeleteDeposite()}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </>
  )
}