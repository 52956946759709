import React from 'react'

import logoImg from "../../images/logo.svg"

import "../../styles/pages/auth.sass"

export default function Index() {
  return (
    <div className="AuthPage">
      <div className="ProjectHeaders">
        <img src={logoImg} alt="" />

        <div className="headers">
          <h1> {process.env.REACT_APP_TITLE} </h1>
          <h2>Manage your business!</h2>
        </div>
      </div>

      <div className="auth-wrapper">
        <div
          style={{
            background: '#f6f7fb',
            borderRadius: '20px',
            boxShadow: '.6rem .4rem .6rem #00000047',
            margin: 'auto',
            maxWidth: '400px',
            padding: '30px 50px',
            textAlign: 'center',
            width: '100%',
          }}
        >
          Page not found!
        </div>
      </div>
    </div>
  )
}
