import { useState } from 'react';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import Iframe from 'react-iframe'


import Icon from '../../components/Icon'
import PdfDocument from './PdfDocument';


import { DataProps } from './InvoiceEdit';

interface PdfViewProps {
  setPage: (value: string) => void
  data: DataProps
  name: string
  id: string
}

export default function PdfView({ setPage, data, name, id }: PdfViewProps) {
  const [url, setUrl] = useState('')

  const generatePdfDocument = async () => {
    const blobPdf = await pdf((
      <PdfDocument
        data={data}
        name={name}
        id={id}
      />
    )).toBlob();
    saveAs(blobPdf, 'fileName');
  };

  return (
    <div className="EstimatePage_Add entity-edit">
      <div className="wrapper flex-container sb">
        <div className='row'>
          <button
            style={{ background: 'inherit' }}
            className="_wa"
            onClick={() => setPage('')}
          >
            <Icon
              style={{ width: '16px', height: '16px', transform: 'rotate(180deg)', fill: '#005eb5' }}
              viewBox="0 0 24 24"
              icon="arrow-25"
            />
          </button>

          <h1 style={{ whiteSpace: 'nowrap', marginTop: '0', color: '#005eb5' }}>View Pdf</h1>
        </div>
      </div>

      <div>
        <button className="_wa" onClick={generatePdfDocument}>View and Save</button>

        <PDFDownloadLink document={<PdfDocument data={data} name={name} id={id} />} >
          {({ blob, url, loading, error }) => {
            url && setUrl(url)
          }}
        </PDFDownloadLink>
      </div>
      <Iframe url={url} />
    </div>
  )
}
