import { useCallback, useEffect, useRef, useState } from "react";
import { useRoute } from "react-router5"
import { useAppSelector } from '../../store/hooks'
import ReactCrop, { Crop } from "react-image-crop"
import Compressor from 'compressorjs';

import Icon from "../../components/Icon"
import AudioPlayer from "./AudioPlayer";
import AudioPlayerModal from "../../components/AudioPlayer";

import { httpClientUpdate, nErrorUpdate } from "../../funcs"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";

import "../../styles/pages/common/entity-edit.sass"

// import User from "../../models/User"
interface HttpClientUpdateReport {
  success: boolean,
  error: {
    code: number,
    message: string
  },
  data: UserData
}

interface UserData {
  permissions: {
    notifications_allow: boolean
    notifications_dispatcherpayout: boolean
    notifications_sms: boolean
    notifications_staffpayout: boolean
    notifications_websiterequest: boolean
    notifications_unsent_appointments: boolean
    notifications_unsent_appointments_all: boolean

    profile_edit_picture_own: boolean
    profile_show_own: boolean

    profile_edit_notifications_sound_own: boolean
    profile_show_notifications_sound_own: boolean
  },
  user: UserProps,
}

interface UserProps {
  first_name: string
  last_name: string
  email: string
  photo_link: string
  notifications_sounds: NotificationsSoundsProps
}

interface NotificationsSoundsProps {
  dispatcherpayout: SoundsProps[]
  sms: SoundsProps[]
  staffpayout: SoundsProps[]
  websiterequest: SoundsProps[]
  unsentappointment: SoundsProps[]
}

interface SoundsProps {
  notifications_sound_id: string
  url: string

  is_account_settings: boolean
}

function generateDownload(canvas: {
  toDataURL(arg0: string, arg1: number): any; toBlob: (arg0: (blob: Blob | MediaSource) => void, arg1: string, arg2: number) => void;
}, crop: any) {
  try {
    if (!crop || !canvas) {
      return;
    }
    canvas.toBlob(
      (blob: Blob | MediaSource) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let file = new File([blob as Blob], "fileName.jpg", { type: "image/jpeg" })
      },
      'image/png',
      1
    );

    return canvas.toDataURL("image/jpeg", 0.95)
  } catch (err) { }
}

function setCanvasImage(image: { naturalWidth: number; width: number; naturalHeight: number; height: number; }, canvas: { getContext: (arg0: string) => any; width: number; height: number; }, crop: { width: number; height: number; x: number; y: number; }) {
  if (!crop || !canvas || !image) {
    return;
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const ctx = canvas.getContext('2d');
  const pixelRatio = window.devicePixelRatio;

  canvas.width = crop.width * pixelRatio * scaleX;
  canvas.height = crop.height * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );
}

async function imageUrlToBase64(url: string) {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
}

function Item() {
  const $router = useRoute()

  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);

  const activeAccountId = useAppSelector((store) => store.activeAccountId)
  const user = useAppSelector((store) => store.user)

  const [isSaving, setIsSaving] = useState(false)

  const [crop, setCrop] = useState<Partial<Crop>>({ unit: '%', width: 100, aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);

  const [draging, setDraging] = useState(false)
  const [avatarPopup, setAvatarPopup] = useState(false)
  const [updatedImage, setUpdatedImage] = useState(false)
  const [userData, setUserData] = useState<UserData | null>(null)
  const [upImg, setUpImg] = useState<string>('');

  const [newUserData, setNewUserData] = useState<Partial<UserProps>>({

  })

  const [updatedSounds, setUpdatedSounds] = useState<{ name: string, data: string, id: null | string, is_account_settings: boolean }>({ name: '', data: '', id: null, is_account_settings: false })
  const [dragingSound, setDragingSound] = useState(false)
  const [playing, setPlaying] = useState('')

  // Load info function
  async function loadInfo() {
    try {
      // https://2022back4.artemiudintsev.com/api/profile/{USER_ID}?account_id=88888
      const { data: { data: userData, success, error } } = await httpClientUpdate.get('/profile/' + user?.user_id, {
        params: {
          account_id: activeAccountId
        }
      }) as { data: HttpClientUpdateReport }
      if (success) {
        if (userData.permissions.profile_show_own) {
          setUserData(userData)
          setNewUserData({
            ...userData.user
          })
          if (userData.permissions.profile_edit_picture_own && userData.user.photo_link) {
            const imageBase64 = await imageUrlToBase64(userData.user.photo_link)

            setUpImg(imageBase64 as string)
          }
        } else {
          $router.router.navigate(`403`, {
            reload: true
          })
        }
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleSave() {
    try {
      setIsSaving(true)
      const reqData: { account_id?: string, photo_link?: string } = {}
      if (activeAccountId) {
        reqData.account_id = activeAccountId
      }
      if (updatedImage) {
        if (upImg) {
          reqData.photo_link = previewCanvasRef.current ? generateDownload(previewCanvasRef.current, crop) : newUserData.photo_link
        } else {
          reqData.photo_link = ''
        }
      }

      if (previewCanvasRef.current && updatedImage) {
        reqData.photo_link = generateDownload(previewCanvasRef.current, crop)
      }

      // https://2022back4.artemiudintsev.com/api/profile/{USER_ID}
      const { data: { success, error } } = await httpClientUpdate.put('/profile/' + user?.user_id, reqData)
      if (success) {
        setUserData(null)
        setUpdatedImage(false)
        setCrop({ unit: '%', width: 100, aspect: 1 })
        setUpImg('')
        loadInfo()
        setIsSaving(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleDeletePhoto() {
    try {
      setIsSaving(true)

      // https://2022back4.artemiudintsev.com/api/profile/{USER_ID}
      const { data: { success, error } } = await httpClientUpdate.put('/profile/' + user?.user_id, {
        account_id: activeAccountId,
        photo_link: '',
      })
      if (success) {
        setUserData(null)
        setUpdatedImage(false)
        setCrop({ unit: '%', width: 100, aspect: 1 })
        setUpImg('')
        loadInfo()
        setIsSaving(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }

  async function handleSaveSound(name: string) {
    try {
      setIsSaving(true)
      let audio_file = updatedSounds.data.replace('data:audio/mpeg;base64,', '')

      // https://2022back4.artemiudintsev.com/api/notification-sounds
      const { data: { success, error } } = await httpClientUpdate.post('/notification-sounds', {
        account_id: activeAccountId,
        audio_file: audio_file,
        type: name,
        use: 1,
      })
      if (success) {
        setUserData(null)
        setUpdatedSounds({ name: '', data: '', id: null, is_account_settings: false })
        loadInfo()
        setIsSaving(false)
      } else {
        $router.router.navigate(`${error.code}`, {
          reload: true
        })
      }
    }
    catch (error: Error | AxiosError | unknown) {
      let createdError = nErrorUpdate(error)
      $router.router.navigate(`${createdError.content.code}`, {
        reload: true
      })
    }
  }


  async function handleRemoveSound(id: string) {
    try {
      setIsSaving(true)
      // https://2022back4.artemiudintsev.com/api/notification-sounds/88888001fd67db3si1
      const response = await httpClientUpdate.delete(`/notification-sounds/${id}`, {
        data: {
          account_id: activeAccountId,
        }
      })
      if (response.data.success) {
        setUpdatedSounds({ data: '', name: '', id: null, is_account_settings: false })
        setIsSaving(false)
        loadInfo()
      } else {
        setIsSaving(false)
      }
    } catch (error) {
      setIsSaving(false)
    }
  }

  // Load user data
  useEffect(() => {
    loadInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (imgRef.current &&
      previewCanvasRef.current &&
      completedCrop) {

      setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop);
    }

  }, [completedCrop]);

  // On select file function
  function onSelectFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 500000) {
        const reader = new FileReader();

        new Compressor(e.target.files[0], {
          quality: 0.6,
          success(result) {
            reader.addEventListener('load', () =>
              setUpImg(reader.result as string)
            );
            reader.readAsDataURL(result);
          },
        })
      } else {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          setUpImg(reader.result as string)
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  }

  const onDragOver = useCallback((e) => {
    setDraging(true)
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDragSoundOver = useCallback((e) => {
    setDragingSound(true)
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const onDragLeave = useCallback((e) => {
    setDraging(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDragSoundLeave = useCallback((e) => {
    setDragingSound(false);
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);

  const onDrop = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setUpImg(reader.result as string)
    );
    reader.readAsDataURL(files[0]);
    setDraging(false);
    return false;
  }, []);

  const onDropSound = useCallback((e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
    reader.addEventListener('load', () =>
      setUpdatedSounds({ ...updatedSounds, data: reader.result as string, id: null })
    );
    reader.readAsDataURL(files[0]);
    setDragingSound(false);
    return false;
  }, [updatedSounds]);

  async function handleCancelUploadImage() {
    if (userData && userData.user.photo_link) {
      const imageBase64 = await imageUrlToBase64(userData.user.photo_link)

      setUpImg(imageBase64 as string)
    }

    setCrop({ unit: '%', width: 100, aspect: 1 })
    setDraging(false)
    setUpdatedImage(false)
    setAvatarPopup(false)
  }

  function handleDeleteImage() {
    handleDeletePhoto()
    setUpImg('')
    setCrop({ unit: '%', width: 100, aspect: 1 })
    setDraging(false)
    setUpdatedImage(true)
    setAvatarPopup(false)
  }

  function onSelectSoundFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setUpdatedSounds({ ...updatedSounds, data: reader.result as string, id: null })
      }
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  // Render function
  return (<>
    {userData ? (
      <div className="UsersPage_Item entity-edit">

        <div className="wrapper flex-container sb">
          <div className="flex-container _gap-narrow">
            <h1>Profile:</h1>
          </div>
        </div>

        <div className="fieldset">
          <div className="legend">Main</div>

          <div className="fields">
            <div className="__left">
              <div className="field">
                <span>First Name:</span>
                <input
                  type="text"
                  disabled={true}
                  style={{ color: '#000' }}
                  defaultValue={userData.user.first_name}
                  onChange={({ target: { value } }) => setNewUserData({ ...newUserData, first_name: value })}
                />
              </div>

              <div className="field">
                <span>Last Name:</span>
                <input
                  type="text"
                  disabled={true}
                  style={{ color: '#000' }}
                  defaultValue={userData.user.last_name}
                  onChange={({ target: { value } }) => setNewUserData({ ...newUserData, last_name: value })}
                />
              </div>
            </div>

            <div className="__right">
              <div className="field">
                <span>Email:</span>
                <input
                  type="text"
                  style={{ color: '#000' }}
                  defaultValue={userData.user.email}
                  disabled={true}
                />
              </div>
            </div>

            {
              userData.permissions.profile_edit_picture_own ?
                <div
                  style={{ display: 'flex', flexDirection: 'column', gap: '10px', cursor: 'pointer' }}
                >
                  <div
                    className="avatar-editing"
                  >
                    <button
                      className="_zeroed _iconed"
                      style={{ cursor: 'inherit' }}
                      onClick={() => {
                        setAvatarPopup(true)
                        setUpdatedImage(true)
                      }}
                    >
                      {
                        updatedImage ?
                          upImg ?
                            <canvas
                              ref={previewCanvasRef}
                              style={{
                                width: '90px',
                                height: '90px',
                              }}
                            /> :
                            <Icon icon="user-20" />
                          :
                          newUserData.photo_link ?
                            <img
                              style={{
                                width: '90px',
                                height: '90px',
                              }}
                              src={newUserData.photo_link}
                              alt="User avatar"
                            /> :
                            <Icon icon="user-20" />
                      }
                    </button>
                  </div>
                  <div
                    onClick={() => {
                      setAvatarPopup(true)
                      setUpdatedImage(true)
                    }}
                  >
                    Change Photo
                  </div>
                </div> :
                <div className="avatar-editing">
                  {
                    newUserData.photo_link ?
                      <img src={newUserData.photo_link} alt="User avatar" /> :
                      <Icon icon="user-20" />
                  }
                </div>
            }
          </div>
        </div>

        {
          userData.permissions.profile_show_notifications_sound_own &&
          <div className="fieldset">
            <div className="legend">Notifications</div>

            {
              userData.permissions.notifications_websiterequest &&
              userData.user.notifications_sounds.websiterequest &&
              <div className="fields">
                <div
                  className="field"
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <span style={{ width: 'fit-content' }}>
                    Website Request
                    {userData.user.notifications_sounds.websiterequest.length ?
                      userData.user.notifications_sounds.websiterequest[0].is_account_settings ?
                        <span style={{ opacity: '.5' }}> (Default sound)</span> :
                        <span style={{ opacity: '.5' }}> (User-customized sound)</span> :
                      ''}
                    :
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '20px',
                    }}
                  >
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!userData.permissions.profile_edit_notifications_sound_own}
                      onClick={() =>
                        setUpdatedSounds({
                          name: 'websiterequest',
                          data: userData.user.notifications_sounds.websiterequest.length ? userData.user.notifications_sounds.websiterequest[0].url : '',
                          id: userData.user.notifications_sounds.websiterequest.length ? userData.user.notifications_sounds.websiterequest[0].notifications_sound_id : null,
                          is_account_settings: userData.user.notifications_sounds.websiterequest.length ? !!userData.user.notifications_sounds.websiterequest[0].is_account_settings : false,
                        })}
                    >
                      <Icon style={{ minWidth: '24px' }} icon="pencil-14" />
                    </button>

                    <AudioPlayer
                      src={userData.user.notifications_sounds.websiterequest.length ? userData.user.notifications_sounds.websiterequest[0].url : ''}
                      disabled={!userData.user.notifications_sounds.websiterequest.length}
                      is_play={playing === 'websiterequest'}
                      name="websiterequest"
                      setPlaying={setPlaying}
                    />
                  </div>
                </div>
              </div>
            }

            {
              userData.permissions.notifications_sms &&
              userData.user.notifications_sounds.sms &&
              <div className="fields">
                <div
                  className="field"
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <span style={{ width: 'fit-content' }}>
                    SMS
                    {userData.user.notifications_sounds.sms.length ?
                      userData.user.notifications_sounds.sms[0].is_account_settings ?
                        <span style={{ opacity: '.5' }}> (Default sound)</span> :
                        <span style={{ opacity: '.5' }}> (User-customized sound)</span> :
                      ''}
                    :
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '20px',
                    }}
                  >
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!userData.permissions.profile_edit_notifications_sound_own}
                      onClick={() =>
                        setUpdatedSounds({
                          name: 'sms',
                          data: userData.user.notifications_sounds.sms.length ? userData.user.notifications_sounds.sms[0].url : '',
                          id: userData.user.notifications_sounds.sms.length ? userData.user.notifications_sounds.sms[0].notifications_sound_id : null,
                          is_account_settings: userData.user.notifications_sounds.sms.length ? !!userData.user.notifications_sounds.sms[0].is_account_settings : false,
                        })}
                    >
                      <Icon style={{ minWidth: '24px' }} icon="pencil-14" />
                    </button>

                    <AudioPlayer
                      src={userData.user.notifications_sounds.sms.length ? userData.user.notifications_sounds.sms[0].url : ''}
                      disabled={!userData.user.notifications_sounds.sms.length}
                      is_play={playing === 'sms'}
                      name="sms"
                      setPlaying={setPlaying}
                    />
                  </div>
                </div>
              </div>
            }

            {
              userData.permissions.notifications_dispatcherpayout &&
              userData.user.notifications_sounds.dispatcherpayout &&
              <div className="fields">
                <div
                  className="field"
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <span style={{ width: 'fit-content' }}>
                    Dispatcher Payout
                    {userData.user.notifications_sounds.dispatcherpayout.length ?
                      userData.user.notifications_sounds.dispatcherpayout[0].is_account_settings ?
                        <span style={{ opacity: '.5' }}> (Default sound)</span> :
                        <span style={{ opacity: '.5' }}> (User-customized sound)</span> :
                      ''}
                    :
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '20px',
                    }}
                  >
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!userData.permissions.profile_edit_notifications_sound_own}
                      onClick={() =>
                        setUpdatedSounds({
                          name: 'dispatcherpayout',
                          data: userData.user.notifications_sounds.dispatcherpayout.length ? userData.user.notifications_sounds.dispatcherpayout[0].url : '',
                          id: userData.user.notifications_sounds.dispatcherpayout.length ? userData.user.notifications_sounds.dispatcherpayout[0].notifications_sound_id : null,
                          is_account_settings: userData.user.notifications_sounds.dispatcherpayout.length ? !!userData.user.notifications_sounds.dispatcherpayout[0].is_account_settings : false,
                        })}
                    >
                      <Icon style={{ minWidth: '24px' }} icon="pencil-14" />
                    </button>

                    <AudioPlayer
                      src={userData.user.notifications_sounds.dispatcherpayout.length ? userData.user.notifications_sounds.dispatcherpayout[0].url : ''}
                      disabled={!userData.user.notifications_sounds.dispatcherpayout.length}
                      is_play={playing === 'dispatcherpayout'}
                      name="dispatcherpayout"
                      setPlaying={setPlaying}
                    />
                  </div>
                </div>
              </div>
            }

            {
              userData.permissions.notifications_staffpayout &&
              userData.user.notifications_sounds.staffpayout &&
              <div className="fields">
                <div
                  className="field"
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <span style={{ width: 'fit-content' }}>
                    Staff Payout
                    {userData.user.notifications_sounds.staffpayout.length ?
                      userData.user.notifications_sounds.staffpayout[0].is_account_settings ?
                        <span style={{ opacity: '.5' }}> (Default sound)</span> :
                        <span style={{ opacity: '.5' }}> (User-customized sound)</span> :
                      ''}
                    :
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '20px',
                    }}
                  >
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!userData.permissions.profile_edit_notifications_sound_own}
                      onClick={() =>
                        setUpdatedSounds({
                          name: 'staffpayout',
                          data: userData.user.notifications_sounds.staffpayout.length ? userData.user.notifications_sounds.staffpayout[0].url : '',
                          id: userData.user.notifications_sounds.staffpayout.length ? userData.user.notifications_sounds.staffpayout[0].notifications_sound_id : null,
                          is_account_settings: userData.user.notifications_sounds.staffpayout.length ? !!userData.user.notifications_sounds.staffpayout[0].is_account_settings : false,
                        })}
                    >
                      <Icon style={{ minWidth: '24px' }} icon="pencil-14" />
                    </button>

                    <AudioPlayer
                      src={userData.user.notifications_sounds.staffpayout.length ? userData.user.notifications_sounds.staffpayout[0].url : ''}
                      disabled={!userData.user.notifications_sounds.staffpayout.length}
                      is_play={playing === 'staffpayout'}
                      name="staffpayout"
                      setPlaying={setPlaying}
                    />
                  </div>
                </div>
              </div>
            }

            {
              (userData.permissions.notifications_unsent_appointments_all || userData.permissions.notifications_unsent_appointments) &&
              userData.user.notifications_sounds.unsentappointment &&
              <div className="fields">
                <div
                  className="field"
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: '20px',
                    flexWrap: 'wrap',
                  }}
                >
                  <span style={{ width: 'fit-content' }}>
                    Unsent Appointment
                    {userData.user.notifications_sounds.unsentappointment.length ?
                      userData.user.notifications_sounds.unsentappointment[0].is_account_settings ?
                        <span style={{ opacity: '.5' }}> (Default sound)</span> :
                        <span style={{ opacity: '.5' }}> (User-customized sound)</span> :
                      ''}
                    :
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: '20px',
                    }}
                  >
                    <button
                      className="_zeroed _iconed _blue"
                      disabled={!userData.permissions.profile_edit_notifications_sound_own}
                      onClick={() =>
                        setUpdatedSounds({
                          name: 'unsentappointment',
                          data: userData.user.notifications_sounds.unsentappointment.length ? userData.user.notifications_sounds.unsentappointment[0].url : '',
                          id: userData.user.notifications_sounds.unsentappointment.length ? userData.user.notifications_sounds.unsentappointment[0].notifications_sound_id : null,
                          is_account_settings: userData.user.notifications_sounds.unsentappointment.length ? !!userData.user.notifications_sounds.unsentappointment[0].is_account_settings : false,
                        })}
                    >
                      <Icon style={{ minWidth: '24px' }} icon="pencil-14" />
                    </button>

                    <AudioPlayer
                      src={userData.user.notifications_sounds.unsentappointment.length ? userData.user.notifications_sounds.unsentappointment[0].url : ''}
                      disabled={!userData.user.notifications_sounds.unsentappointment.length}
                      is_play={playing === 'unsentappointment'}
                      name="unsentappointment"
                      setPlaying={setPlaying}
                    />
                  </div>
                </div>
              </div>
            }
          </div>
        }

        {avatarPopup ? (
          <div
            className="popup imageCrop"
            onClick={() => setAvatarPopup(false)}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={(event) => {
              draging && onDrop(event)
            }}
          >

            <div
              className="wrapper"
              onClick={(e) => e.stopPropagation()}
            >

              {
                draging ?
                  <div
                    style={{
                      border: '2px solid rgb(96, 147, 222)',
                      borderRadius: '5px',
                      position: 'relative'
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                      name="myImage"
                      onChange={onSelectFile}
                      id="file-uploader"
                    />
                    <span
                      style={{ position: 'absolute', top: '50px', width: '100%', textAlign: 'center', color: '#6093de' }}
                    >
                      Upload a File or click for refresh field
                    </span>
                  </div> :
                  <div>
                    <input type="file" accept="image/*" onChange={onSelectFile} id="file-uploader" />
                    <button
                      className="_wa _iconed _bordered _blue"
                      style={{ marginTop: upImg ? '' : '40px' }}
                      onClick={() => document.getElementById('file-uploader')?.click()}
                    >
                      <Icon icon="user-33" />
                      <span>Upload a File</span>
                    </button>
                  </div>
              }


              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '10px'
                }}
              >
                {
                  upImg &&
                  <ReactCrop
                    src={upImg}
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                  />
                }
              </div>

              {
                upImg &&
                <div
                  style={{ display: 'flex', marginTop: '10px' }}
                >
                  <button
                    className="_wa _iconed _bordered _blue"
                    onClick={() => handleCancelUploadImage()}
                  >
                    <span>Cancel</span>
                  </button>

                  <button
                    className="_wa _iconed _bordered _red"
                    onClick={() => handleDeleteImage()}
                  >
                    Delete
                  </button>

                  <button
                    className="_wa _iconed _bordered _blue"
                    onClick={() => {
                      setAvatarPopup(false)
                      setDraging(false)
                      handleSave()
                    }}
                  >
                    <span>Save</span>
                  </button>
                </div>
              }
            </div>
          </div>
        ) : null}

        {
          updatedSounds.name &&
          <div
            className="popup imageCrop"
            onClick={() => setUpdatedSounds({ name: '', data: '', id: null, is_account_settings: false })}
            onDragLeave={onDragSoundLeave}
            onDragOver={onDragSoundOver}
            onDrop={(event) => {
              dragingSound && onDropSound(event)
            }}
          >

            <div
              className="wrapper"
              onClick={(e) => e.stopPropagation()}
            >

              {
                updatedSounds.data &&
                <AudioPlayerModal
                  audioSrc={updatedSounds.data}
                />
              }

              {
                updatedSounds.data &&
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: '10px'
                  }}
                >
                </div>
              }

              {
                dragingSound ?
                  <div
                    style={{
                      border: '2px solid rgb(96, 147, 222)',
                      borderRadius: '5px',
                      position: 'relative'
                    }}
                  >
                    <input
                      type="file"
                      accept="audio/*"
                      style={{ width: '100%', height: '120px', display: 'block', opacity: '0', zIndex: '2' }}
                      onChange={onSelectSoundFile}
                      id="file-uploader-sound"
                    />
                    <span
                      style={{ position: 'absolute', top: '50px', width: '100%', textAlign: 'center', color: '#6093de' }}
                    >
                      Upload a File or click for refresh field
                    </span>
                  </div> :
                  <div>
                    <input type="file" accept="audio/*" onChange={onSelectSoundFile} id="file-uploader-sound" />
                    <button
                      className="_wa _iconed _bordered _blue"
                      style={{ marginTop: upImg ? '' : '40px' }}
                      onClick={() => document.getElementById('file-uploader-sound')?.click()}
                    >
                      <span>Upload a File</span>
                    </button>
                  </div>
              }


              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '10px'
                }}
              >
              </div>

              {
                updatedSounds.name &&
                <div
                  style={{ display: 'flex', marginTop: '10px' }}
                >
                  <button
                    className="_wa _iconed _bordered _blue"
                    onClick={() => setUpdatedSounds({ name: '', data: '', id: null, is_account_settings: false })}
                  >
                    <span>Cancel</span>
                  </button>

                  <button
                    className="_wa _iconed _bordered _red"
                    disabled={(!updatedSounds.id && !updatedSounds.data) || updatedSounds.is_account_settings}
                    onClick={() => updatedSounds.id ?
                      !updatedSounds.is_account_settings && handleRemoveSound(updatedSounds.id) :
                      setUpdatedSounds({ data: '', name: '', id: null, is_account_settings: false })}
                  >
                    Delete
                  </button>

                  <button
                    className="_wa _iconed _bordered _blue"
                    disabled={isSaving}
                    onClick={() => {
                      setDragingSound(false)
                      handleSaveSound(updatedSounds.name)
                    }}
                  >
                    <span>Save</span>
                  </button>
                </div>
              }
            </div>
          </div>
        }
      </div>
    ) : null
    }
  </>)
}

export default Item
